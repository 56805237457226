input,
textarea {
  outline: none;
  margin: 0;
  padding: 0;
}
@-webkit-keyframes autofill {
  0%,
  100% {
    background-color: #F6F5FA;
    color: #1F2126;
  }
}
body {
  font-family: 'PingFang SC', -apple-system, Source Han sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
button,
input,
select,
textarea {
  font-family: 'PingFang SC', -apple-system, Source Han sans-serif, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-color: #efefef;
}
input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
