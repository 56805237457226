.ant-message-notice .ant-message-notice-content {
  background: #000;
  box-shadow: none;
}
.ant-message-notice .ant-message-notice-content .ant-message-custom-content {
  color: #fff;
}
.ant-message-notice .ant-message-notice-content .ant-message-custom-content .anticon {
  display: none;
}
@media only screen and (min-width: 768px) {
  .ant-message {
    z-index: 10000 !important;
  }
  .ant-message .ant-message-notice .ant-message-notice-content {
    background: #fff;
    box-shadow: none;
    border-radius: 20px;
  }
  .ant-message .ant-message-notice .ant-message-notice-content .ant-message-custom-content {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
  .ant-message .ant-message-notice .ant-message-notice-content .ant-message-custom-content .anticon {
    display: inline-block;
    top: 3px;
  }
}
